<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage finance transaction headings</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Finance
                  transaction headings
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="__add()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    heading
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Title"
                    type="text"
                    dense
                    outlined
                    v-model="search.title"
                  />
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    v-model="search.active"
                    clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" md="5" class="text-right">
                  <v-btn
                    :loading="loading"
                    @click="__get"
                    class="btn btn-primary btn-search w-35"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr>
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Type</th>
                    <th class="px-3 wrap-column">Status</th>
                    <th class="px-2 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(head, index) of financial_heads" :key="index">
                  <td scope="row" class="px-3 wrap-column">
                    <a href="#" @click="__edit(head.id)" class="mr-2">
                      {{ head.title }}</a
                    >
                  </td>
                  <!--                      <td>
                        <p v-html="head.description ">
                        </p>
                      </td>-->

                  <td class="px-3 wrap-column">
                    {{ head.type_text }}
                  </td>
                  <td class="px-3 pt-3 wrap-column">
                    <span
                      class="badge text-lg`"
                      v-bind:class="{
                        'badge-success': head.is_active,
                        'badge-danger': !head.is_active,
                      }"
                      >{{ head.is_active ? "Active" : "Inactive" }}</span
                    >
                  </td>
                  <td class="px-2 text-center">
                    <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i> </span
                        ></slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">
                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" class="navi-link" @click="openPaymentType(head.id)">
                                  <span class="navi-icon">
                                    <i class="fas fa-eye"></i>
                                  </span>
                            <span class="navi-text">Invoice particular </span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" class="navi-link" @click="__edit(head.id)">
                            <span class="navi-icon">
                              <i class="flaticon-edit"></i>
                            </span>
                            <span class="navi-text"> Edit </span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" class="navi-link" @click="__delete(head.id)">
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text"> Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </td>
                </tr>
                <tr v-if="financial_heads.length == 0">
                  <td colspan="4" class="text-center">
                    <strong>Data not available</strong>
                  </td>
                </tr>
              </tbody>
              </table>
              <b-pagination
                v-if="financial_heads.length > 0"
                class="pull-right mt-7"
                @input="__get"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>

          </div>
          <create-and-update ref="head" @refresh_head="__get"></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { SiteSettingMixin } from "@/mixins/SiteSettingMixin";
import CreateAndUpdate from "./CreateAndUpdate";
import draggable from "vuedraggable";
import FinancialTransactionHeadsService from "@/core/services/finance/FinancialTransactionHeadsService";
const financialTransactionHeadsService = new FinancialTransactionHeadsService();

export default {
  name: "financial_heads",
  mixins: [SiteSettingMixin],
  display: "Table",
  order: 8,
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      financial_heads: [],
      page: null,
      perPage: null,
      total: null,
      search: {
        active: "active",
      },
      dragging: false,
      loading: false,
    };
  },
  mounted() {
    this.search.transaction_head_id = this.$route.params.transactionHeadId || '';
    this.__get();
  },
  methods: {
    __get() {
      this.loading = true;
      financialTransactionHeadsService
        .paginate(this.search)
        .then((response) => {
          this.financial_heads = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          // console.log(error);
        });
    },
    __add() {
      this.$refs["head"].showModal();
    },
    __edit(id) {
      this.$refs["head"].showModal(id);
    },
    manageCategory() {
      this.$refs["manageCategory"].showModal();
    },

    __delete(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            financialTransactionHeadsService
              .delete(id)
              .then((response) => {
                this.__get();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
    sort() {
      financialTransactionHeadsService
        .sort(this.financial_heads)
        .then((res) => {
          this.$snotify.success("Sorted");
          this.__get();
        })
        .catch((err) => {
          this.$snotify.success("Sorted Error");
        });
    },
    openPaymentType(id) {
      this.$tabs.open({ name: "finance-payment-type", params: { transactionHeadId: id } });
    },
  },
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}
</style>
