<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
              <v-toolbar dark>
                <v-card-title class="headline">
                  {{ `${edit ? 'Update' : 'Add'}` }} financial transaction heading
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="resetForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <v-text-field label="Title" v-model="head.title" outlined dense>

                            </v-text-field>
                            <span class="text-danger" v-if="$v.head.title.$error">This information is required</span>

                        </v-col>
                      <v-col cols="6">
                        <v-select
                            :items="types"
                            v-model="head.type"
                            outlined
                            dense
                            label="Type"
                            item-text="title"
                            item-value="value"
                        >

                        </v-select>
                        <span class="text-danger"
                              v-if="$v.head.type.$error">This information is required</span>
                      </v-col>
                        <div class="col-12">
                            Description
                            <ckeditor v-model="head.description" :config="editorConfig"></ckeditor>
                            <span class="text-danger"
                                  v-if="$v.head.description.$error">This information is required</span>
                        </div>

                        <div class="col-3">
                            <v-switch
                                    v-model="head.is_active"
                                    label="Status"
                            >
                            </v-switch>
                        </div>
                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn depressed @click="resetForm" class="text-gray btn btn-standard  cancel-btn">
                                    Cancel
                                </v-btn>
                                <v-btn depressed :loading="isBusy" @click="createOrUpdate"
                                       class="text-white ml-2 btn btn-primary">
                                    Save
                                </v-btn>
                            </div>

                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import FinancialTransactionHeadsService from "@/core/services/finance/FinancialTransactionHeadsService";

    const financialTransactionHeadsService = new FinancialTransactionHeadsService();

    export default {
        name: 'heads',
        validations: {
            head: {
                title: {required},
                description: {required},
                type: {required},

            }
        },
        computed: {},
        data() {
            return {
                dialog: false,
                edit: false,
                sizeExceed: false,
                isBusy: false,
                search: {
                    active: "active",
                    is_parent: 1,
                },
                types: [
                    {
                        title: 'Expense',
                        value: 'expense'
                    },
                    {
                        title: 'Income',
                        value: 'income'
                    },
                    {
                        title: 'Additional fee',
                        value: 'additional_fee'
                    },
                ],
                head: {
                    title: null,
                    description: null,
                    type: null,
                    is_active: true
                },
              editorConfig: {
                versionCheck: false,
                toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ]
              },
            };
        },

        methods: {
            showModal(id = null) {
                this.__reset();
                if (id) {
                    this.edit = true
                    this.__get(id)
                } else
                    this.edit = false;

                this.dialog = true;
                // this.$refs["my-modal"].show();
            },
            __reset() {
                this.head = {
                    title: null,
                    description: null,
                    type: null,
                    is_active: true
                }
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                this.$refs["my-modal"].toggle("#toggle-btn");
            },
            __get(id) {
                financialTransactionHeadsService.show(id).then(response => {
                    this.head = response.data.head
                })
            },

            createOrUpdate() {
                this.$v.head.$touch();
                if (this.$v.head.$error) {
                    setTimeout(() => {
                        this.$v.head.$reset();
                    }, 3000);
                } else {
                    let fd = this.head;
                    if (this.edit) {
                        this.__update(fd);
                    } else {
                        this.__create(fd);
                    }
                }
            },
            __update(fd) {
                this.isBusy = true;
                financialTransactionHeadsService
                    .update(this.head.id, fd)
                    .then(response => {
                        this.isBusy = false;
                        this.$snotify.success("Information updated");

                        this.resetForm();
                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        // console.log(error);
                    });
            },

            checkSize(file) {
                let size = file.size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
            },
            __create(fd) {
                this.isBusy = true;
                financialTransactionHeadsService
                    .store(fd)
                    .then((response) => {
                      this.isBusy = false;
                        this.resetForm();
                        this.$snotify.success("Information added");

                    })
                    .catch(error => {
                        this.isBusy = false;
                        this.$snotify.error("Something Went Wrong");
                        console.log(error);
                    });
            },

            resetForm() {
                this.$v.head.$reset();
                this.edit = false;
                this.head = {
                    title: null,
                    description: null,
                    is_active: null
                };
                this.hideModal();
                this.$emit("refresh_head");
            }
        }
    };
</script>
